import {
    PopupNotification,
} from 'devlink';
import { AdminContext } from 'pages/admin/admin-page.model';
import React, { useCallback, useContext, useEffect } from 'react';

export function PopupAdminConfirmContainer() {
    const { isConfirmationOpen, setIsConfirmationOpen, adminStore } =
        useContext(AdminContext)


    const handleClosePopup = useCallback(() => {
        setIsConfirmationOpen?.(false);
    }, [setIsConfirmationOpen]);

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup();
            }
        };

        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [handleClosePopup]);

    return (
        <PopupNotification
            visibility={isConfirmationOpen}
            rpClosePopUp={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsConfirmationOpen?.(false)
                },
            }}
            rpInfoText={{
                children: 'Really?',
            }}
            rpIcon={{
                className: 'fas fa-exclamation-triangle',
            }}
            // rpButtonConfirm={{
            //     className: 'button is-primary',
            //     onClick: (e: React.MouseEvent) => {
            //         e.preventDefault()
            //         setIsConfirmationOpen?.(false)
            //         adminStore.getState().reset()
            //         window.location.reload()
            //     },
            // }}
        />
    )
}
