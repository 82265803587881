import { useLayoutEffect, useState } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import Select from 'react-select'
import * as _Builtin from '../_Builtin'
import { ButtonIcon } from '../_Component/ButtonIcon'
import { ButtonText } from '../_Component/ButtonText'

createTheme(
    'createTableTheme',
    {
        text: {
            primary: '#f7f7f7',
            secondary: '#727eab',
        },
        background: {
            default: '#2b3162',
        },
        // update color for hovering over rows
        striped: {
            default: '#C62828',
            selected: '#C62828',
        },
        context: {
            background: '#C62828',
            text: '#C62828',
        },
        divider: {
            default: '#878b9b',
        },
        highlightOnHover: {
            default: '#404a8e',
            text: '#f7f7f7',
        },
        // sortFocus: {
        //     default: '#2b3162',
        // },
        // selected: {
        //     default: '#C62828',
        //     text: '#C62828',
        // },
        // highlight: {
        //     default: '#C62828',
        //     text: '#C62828',
        // },
        // button: {
        //     default: '#C62828',
        //     focus: '#C62828',
        //     hover: '#C62828',
        // },
        // checkbox: {
        //     default: '#C62828',
        //     checked: '#C62828',
        //     disabled: '#C62828',
        // },
        // pagination: {
        //     background: '#6200EA',
        //     text: '#6200EA',
        //     active: '#6200EA',
        // },
        // header: {
        //     background: '#C62828',
        //     text: '#C62828',
        // },
        // contextMenu: {
        //     background: '#C62828',
        //     text: '#C62828',
        // },
        // footer: {
        //     background: '#C62828',
        //     text: '#C62828',
        // },
        // expander: {
        //     default: '#C62828',
        //     disabled: '#C62828',
        // },
        // filter: {
        //     default: '#C62828',
        //     disabled: '#C62828',
        // },
        // scrollbar: {
        //     track: '#C62828',
        //     thumb: '#C62828',
        // },
        // overlay: {
        //     background: '#C62828',
        //     text: '#C62828',
        // },
        // drag: {
        //     default: '#C62828',
        //     active: '#C62828',
        // },
        // drop: {
        //     default: '#C62828',
        // },
        // input: {
        //     default: '#C62828',
        //     disabled: '#C62828',
        //     focus: '#C62828',
        //     hover: '#C62828',
        // },
    },
    'dark'
)

export function DisplayUsers({
    as: _Component = _Builtin.Section,
    users,
    teamOptions,
    disabled,
    loading,
}) {
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    // when user changes, update the columns and data variable
    useLayoutEffect(() => {
        if (users.length > 0) {
            // Set columns only for name, email, date_joined
            const columns = [
                {
                    name: 'Name',
                    selector: (row) => row.name,
                    sortable: true,
                    // grow: 2,
                },
                {
                    name: 'Email',
                    selector: (row) => row.email,
                    sortable: true,
                    // grow: 2,
                },
                {
                    name: 'Date Joined',
                    selector: (row) => row.date_joined,
                    sortable: true,
                    // grow: 1,
                },
            ]
            // const columns = Object.keys(users[0]).map((key) => {
            //     return {
            //         name: key,
            //         selector: (row) => row[key],
            //         sortable: true,
            //     }
            // })
            setColumns(columns)
            setData(users)
        }
    }, [users])

    return (
        <_Component
            className="section"
            grid={{
                type: 'section',
            }}
            tag="section"
        >
            <_Builtin.Block
                className="container-large padding-horizontal padding-small"
                tag="div"
            >
                <_Builtin.Block
                    className="section-title-wrapper margin-bottom margin-xsmall"
                    tag="div"
                >
                    <_Builtin.Heading tag="h2">
                        {'Display Users'}
                    </_Builtin.Heading>
                </_Builtin.Block>
                <DataTable
                    className="dropdown"
                    // Documentation: https://react-data-table-component.netlify.app/?path=/docs/getting-started-kitchen-sink--docs
                    columns={columns}
                    data={data}
                    expandableRows
                    expandableRowsComponent={ExpandableRowComponent}
                    expandableRowsComponentProps={{
                        saveProps: {
                            onClick: (data) => console.log('data', data.target),
                        },
                        TeamsDropdown: {
                            placeholder: 'Choose target teams...',
                            isDisabled: false,
                            options: teamOptions,
                        },
                    }}
                    direction="auto"
                    fixedHeaderScrollHeight="300px"
                    highlightOnHover
                    pagination
                    pointerOnHover
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    theme="createTableTheme"
                    disabled={disabled}
                    progressPending={loading}
                />
            </_Builtin.Block>
        </_Component>
    )
}

const ExpandableRowComponent = ({ data, saveProps, TeamsDropdown }) => {
    const [selectedTeamsOptions, setSelectedTeamsOptions] = useState([])

    const customStyles = {
        control: (provided) => ({
            ...provided,
            marginBottom: '0px',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'hsla(0, 0%, 100%, 0)',
            borderRadius: '4px',
            backgroundColor: '#262c47',
            color: 'white',
            fontSize: '1rem',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#262c47', // Background color of the dropdown menu
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? '#262c47'
                : state.isFocused
                  ? '#404a8e'
                  : '#262c47',
            color: state.isSelected ? 'lightgrey' : 'white',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#727eab',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: 'white',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: 'white',
            ':hover': {
                backgroundColor: '#878b9b',
                color: 'white',
            },
        }),
        input: (provided) => ({
            ...provided,
            color: '#878b9b', // Color of the typed text
        }),
        // placeholder: (provided) => ({
        //     ...provided,
        //     color: 'white', // Color of the placeholder text
        // }),
    }

    return (
        <_Builtin.Block
            className="flex-center flex gap-8px padding padding-small"
            tag="div"
        >
            <_Builtin.Block className="npc-form padding-small" tag="div">
                <_Builtin.FormBlockLabel
                    className="text-size-medium margin-bottom margin-xxsmall"
                    htmlFor="NPC-3"
                >
                    {'Access Right (current Team):'}
                </_Builtin.FormBlockLabel>
                <_Builtin.Block
                    className="light-border margin-bottom margin-small"
                    tag="div"
                >
                    <_Builtin.FormCheckboxWrapper id="w-node-c9494380-7ef0-5b2e-4546-06c8ae4da874-ae4da865">
                        <_Builtin.FormCheckboxInput
                            type="checkbox"
                            name="is_owner"
                            data-name="is_owner"
                            checked={data.can_manage_npcs}
                            required={false}
                            id="is_owner"
                            form={{
                                type: 'is_owner-input',
                                name: 'is_owner',
                            }}
                            inputType="custom"
                            customClassName="w-checkbox-input--inputType-custom"
                            onChange={() => console.log('Checkbox clicked')}
                        />
                        <_Builtin.FormInlineLabel>
                            {'Is Owner'}
                        </_Builtin.FormInlineLabel>
                    </_Builtin.FormCheckboxWrapper>
                    <_Builtin.FormCheckboxWrapper id="w-node-c9494380-7ef0-5b2e-4546-06c8ae4da874-ae4da865">
                        <_Builtin.FormCheckboxInput
                            type="checkbox"
                            name="is_admin"
                            data-name="is_admin"
                            checked={data.can_manage_npcs}
                            required={false}
                            id="is_admin"
                            form={{
                                type: 'is_admin-input',
                                name: 'is_admin',
                            }}
                            inputType="custom"
                            customClassName="w-checkbox-input--inputType-custom"
                            onChange={() => console.log('Checkbox clicked')}
                        />
                        <_Builtin.FormInlineLabel>
                            {'Is Admin'}
                        </_Builtin.FormInlineLabel>
                    </_Builtin.FormCheckboxWrapper>
                    <_Builtin.FormCheckboxWrapper id="w-node-c9494380-7ef0-5b2e-4546-06c8ae4da874-ae4da865">
                        <_Builtin.FormCheckboxInput
                            type="checkbox"
                            name="can_manage_npcs"
                            data-name="can_manage_npcs"
                            checked={data.can_manage_npcs}
                            required={false}
                            id="can_manage_npcs"
                            form={{
                                type: 'can_manage_npcs-input',
                                name: 'can_manage_npcs',
                            }}
                            inputType="custom"
                            customClassName="w-checkbox-input--inputType-custom"
                            onChange={() => console.log('Checkbox clicked')}
                        />
                        <_Builtin.FormInlineLabel>
                            {'Can Manage NPCs'}
                        </_Builtin.FormInlineLabel>
                    </_Builtin.FormCheckboxWrapper>
                    <_Builtin.FormCheckboxWrapper id="w-node-c9494380-7ef0-5b2e-4546-06c8ae4da874-ae4da865">
                        <_Builtin.FormCheckboxInput
                            type="checkbox"
                            name="can_manage_models"
                            data-name="can_manage_models"
                            checked={data.can_manage_npcs}
                            required={false}
                            id="can_manage_models"
                            form={{
                                type: 'can_manage_models-input',
                                name: 'can_manage_models',
                            }}
                            inputType="custom"
                            customClassName="w-checkbox-input--inputType-custom"
                            onChange={() => console.log('Checkbox clicked')}
                        />
                        <_Builtin.FormInlineLabel>
                            {'Can Manage Models'}
                        </_Builtin.FormInlineLabel>
                    </_Builtin.FormCheckboxWrapper>
                </_Builtin.Block>
                <_Builtin.Block className="button-group" tag="div">
                    <_Builtin.Link
                        className="button"
                        button={false}
                        block="inline"
                        options={{
                            href: '#',
                        }}
                        // {...rdButtonGrant}
                    >
                        <_Builtin.Block className="slot-button" tag="div">
                            <ButtonIcon buttonIconText="&#xe914;" />
                            <ButtonText buttonText="Change Access Rights" />
                        </_Builtin.Block>
                    </_Builtin.Link>
                </_Builtin.Block>
            </_Builtin.Block>

            <_Builtin.Block className="npc-form padding-small" tag="div">
                <_Builtin.FormBlockLabel
                    className="text-size-medium margin-bottom margin-xxsmall"
                    htmlFor="NPC-3"
                >
                    {'Allow User for Teams:'}
                </_Builtin.FormBlockLabel>
                <_Builtin.Block
                    className="light-border margin-bottom margin-small"
                    tag="div"
                >
                    <Select
                        {...TeamsDropdown}
                        value={selectedTeamsOptions}
                        onChange={(selectedOptions) => {
                            setSelectedTeamsOptions(selectedOptions)
                        }}
                        closeMenuOnSelect={false}
                        isMulti
                        styles={customStyles}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: '#878b9b',
                                primary: 'black',
                            },
                        })}
                    />
                </_Builtin.Block>
                <_Builtin.Block className="button-group" tag="div">
                    <_Builtin.Link
                        className="button"
                        button={false}
                        block="inline"
                        options={{
                            href: '#',
                        }}
                        // {...rdButtonAssign}
                    >
                        <_Builtin.Block className="slot-button" tag="div">
                            <ButtonIcon buttonIconText="&#xe914;" />
                            <ButtonText buttonText="Assign Team" />
                        </_Builtin.Block>
                    </_Builtin.Link>
                </_Builtin.Block>
            </_Builtin.Block>            
        </_Builtin.Block>
        
    )
}
