import { AdminModel } from 'entities/admin'
import { createContext, useState } from 'react'
import { StoreApi, createStore } from 'zustand'
import { DevtoolsOptions, devtools } from 'zustand/middleware'

const initialAdminState: AdminModel.State = {
    userStates: [
        {
            id: undefined,
            name: '',
            email: '',
            is_owner: false,
            is_admin: false,
            can_manage_models: false,
            can_manage_npcs: false,
            date_joined: '',
        },
    ],
}

const adminStoreDevtoolsOptions: DevtoolsOptions = {
    name: 'AdminPage AdminStore',
}

const playerStoreDevtoolsOptions: DevtoolsOptions = {
    name: 'AdminPage AdminStore',
}

export const adminStore = createStore<AdminModel.AdminState>()(
    devtools(
        AdminModel.createAdminSlice(initialAdminState),
        adminStoreDevtoolsOptions
    )
)

type AdminContextType = {
    adminStore: StoreApi<AdminModel.AdminState>
    setIsConfirmationOpen?: (value: boolean) => void
    isConfirmationOpen: boolean
}

export const AdminContext = createContext<AdminContextType>({
    adminStore,
    isConfirmationOpen: false
})

export const AdminProvider = ({ children }: { children: React.ReactNode }) => {
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

    return (
        <AdminContext.Provider
            value={{
                adminStore,
                isConfirmationOpen: isConfirmationOpen,
                setIsConfirmationOpen: setIsConfirmationOpen,
            }}
        >
            {children}
        </AdminContext.Provider>
    )
}

