import {
    faBriefcase,
    faComments,
    faDatabase,
    faDice,
    faEnvelope,
    faPuzzlePiece,
    faRightFromBracket,
    faUsersViewfinder,
    faVolumeLow,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Button,
    ButtonIcon,
    ButtonText,
    NavbarDivider,
    Sidebar,
    SidebarLayout,
} from 'devlink'
import { sessionQueries } from 'entities/session'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { pathKeys } from 'shared/lib/react-router'

export function GenericLayout() {
    const location = useLocation()
    const isGuest = location.pathname.startsWith(pathKeys.login())

    return <>{isGuest ? <GuestNavigation /> : <UserNavigation />}</>
}

export function GuestLayout() {
    return (
        <>
            <GuestNavigation />
        </>
    )
}

export function NakedLayout() {
    return <Outlet />
}

function UserNavigation() {
    return (
        <>
            <SidebarLayout
                slotSidebarLayout={
                    <>
                        <SidebarContainer />
                        <Outlet />
                    </>
                }
            />
        </>
    )
}

function GuestNavigation() {
    return <Outlet />
}

function SidebarContainer() {
    const location = useLocation()
    const navigate = useNavigate()

    const navlinksTop = [
        {
            label: 'Voice',
            icon: faVolumeLow,
            to: pathKeys.voice(),
            isCurrent: location.pathname.startsWith(pathKeys.voice()),
        },
        {
            label: 'Dialogue',
            icon: faComments,
            to: pathKeys.dialog(),
            isCurrent: location.pathname.startsWith(pathKeys.dialog()),
        },
        {
            label: 'Roll a Dice',
            icon: faDice,
            to: pathKeys.roll(),
            isCurrent: location.pathname.startsWith(pathKeys.roll()),
        },
        {
            label: 'Animation',
            icon: faUsersViewfinder,
            to: pathKeys.animation(),
            isCurrent: location.pathname.startsWith(pathKeys.animation()),
        },
    ]

    const navlinksBottom = [
        {
            label: 'Dataset',
            icon: faDatabase,
            to: pathKeys.dataset(),
            isCurrent: location.pathname.startsWith(pathKeys.dataset()),
        },
        {
            label: 'Integration',
            icon: faPuzzlePiece,
            to: pathKeys.integration(),
            isCurrent: location.pathname.startsWith(pathKeys.integration()),
        },
        // {
        //     label: 'Admin',
        //     icon: faBriefcase,
        //     to: pathKeys.admin(),
        //     isCurrent: location.pathname.startsWith(pathKeys.admin()),
        // },

        {
            label: 'Contact',
            icon: faEnvelope,
            to: pathKeys.contact(),
            isCurrent: location.pathname.startsWith(pathKeys.contact()),
        },
    ]

    const { mutate: logout, isPending: isLogoutPending } =
        sessionQueries.useLogoutMutation()

    return (
        <Sidebar
            slotSidebarNavlinks={
                <>
                    {navlinksTop.map((navlink) => (
                        <Button
                            key={navlink.to}
                            rpButton={{
                                style: {
                                    cursor: `${navlink.isCurrent ? 'default' : 'pointer'}`,
                                },
                                className: `button is-navlink ${navlink.isCurrent ? '' : 'is-secondary'}`,
                                onClick: (e: Event) => {
                                    e.preventDefault()
                                    if (navlink.isCurrent) return

                                    navigate(navlink.to)
                                },
                            }}
                            slotButton={
                                <div className="is-navlink">
                                    <ButtonIcon
                                        buttonIconText={
                                            <FontAwesomeIcon
                                                icon={navlink.icon}
                                                size="1x"
                                            />
                                        }
                                    />
                                    <ButtonText buttonText={navlink.label} />
                                </div>
                            }
                        />
                    ))}

                    <NavbarDivider />

                    {navlinksBottom.map((navlink) => (
                        <Button
                            key={navlink.to}
                            rpButton={{
                                style: {
                                    cursor: `${navlink.isCurrent ? 'default' : 'pointer'}`,
                                },
                                className: `button is-navlink ${navlink.isCurrent ? '' : 'is-secondary'}`,
                                onClick: (e: Event) => {
                                    e.preventDefault()
                                    if (navlink.isCurrent) return

                                    navigate(navlink.to)
                                },
                            }}
                            slotButton={
                                <div className="is-navlink">
                                    <ButtonIcon
                                        buttonIconText={
                                            <FontAwesomeIcon
                                                icon={navlink.icon}
                                                size="1x"
                                            />
                                        }
                                    />
                                    <ButtonText buttonText={navlink.label} />
                                </div>
                            }
                        />
                    ))}
                </>
            }
            slotNavbarLogout={
                <>
                    {isLogoutPending ? (
                        <img
                            src="/images/xandi_running.gif"
                            alt="xandi running"
                            style={{ width: '36px' }}
                        />
                    ) : (
                        <>
                            
                            {/* <Button
                                rpButton={{
                                    className:
                                        'button is-transparent is-navlink',
                                    onClick: (e: Event) => {
                                        console.log("Select Team")
                                    },
                                }}
                                slotButton={
                                    <div className="is-navlink">
                                        <FontAwesomeIcon
                                            icon={faPeopleGroup}
                                            size="2x"
                                        />
                                        <ButtonText buttonText="Team" />
                                    </div>
                                }
                            /> */}
                            <NavbarDivider />
                            <Button
                                rpButton={{
                                    className:
                                        'button is-transparent is-navlink',
                                    onClick: (e: Event) => {
                                        e.preventDefault()
                                        logout()
                                    },
                                }}
                                slotButton={
                                    <div className="is-navlink">
                                        <FontAwesomeIcon
                                            icon={faRightFromBracket}
                                            size="2x"
                                        />
                                        <ButtonText buttonText="Log out" />
                                    </div>
                                }
                            />
                        </>
                    )}
                </>
            }
        />
    )
}
