import * as _Builtin from '../_Builtin'
import { ButtonIcon } from '../_Component/ButtonIcon'
import { ButtonText } from '../_Component/ButtonText'
export function RemoveTeam({
    as: _Component = _Builtin.Section,
    rdTeam,
    rdButtonRemove,
}) {
    return (
        <_Component
            className="section"
            grid={{
                type: 'section',
            }}
            tag="section"
        >
            <_Builtin.Block
                className="container-large padding-horizontal padding-small"
                tag="div"
            >
                <_Builtin.Block
                    className="section-title-wrapper margin-bottom margin-xsmall"
                    tag="div"
                >
                    <_Builtin.Heading tag="h2">
                        {'Delete Team'}
                    </_Builtin.Heading>
                </_Builtin.Block>
                <_Builtin.Block className="section-content-wrapper" tag="div">
                    <_Builtin.FormBlockLabel
                        className="text-size-small margin-bottom margin-xxsmall"
                        htmlFor="field"
                    >
                        {'Select the Teams to remove'}
                    </_Builtin.FormBlockLabel>
                    <_Builtin.Block
                        className="light-border margin-bottom margin-xsmall"
                        tag="div"
                    >
                        <_Builtin.FormTextInput
                            className="input background-blue-150 min-h-3-4rem"
                            required={false}
                            autoFocus={false}
                            placeholder="Give the new team a name"
                            maxLength={200}
                            name="field-2"
                            data-name="Field 2"
                            {...rdTeam}
                        />
                    </_Builtin.Block>
                    <_Builtin.Block className="button-group" tag="div">
                        <_Builtin.Link
                            className="button is-red"
                            button={false}
                            block="inline"
                            options={{
                                href: '#',
                            }}
                            {...rdButtonRemove}
                        >
                            <_Builtin.Block className="slot-button" tag="div">
                                <ButtonIcon buttonIconText="&#xe919;" />
                                <ButtonText buttonText="Delete Team" />
                            </_Builtin.Block>
                        </_Builtin.Link>
                    </_Builtin.Block>
                </_Builtin.Block>
            </_Builtin.Block>
        </_Component>
    )
}
