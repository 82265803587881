import { StateCreator } from 'zustand'

export type User = {
    id?: number
    name: string
    email: string
    is_owner: boolean
    is_admin: boolean
    can_manage_models: boolean
    can_manage_npcs: boolean
    date_joined: string
}

export type State = {
    userStates: User[]
}

export type Actions = {
    setUser: (id: number, character?: User) => void
    save: () => void
    load: () => void
    reset: () => void
}

export type AdminState = State & Actions
export const createAdminSlice =
    (
        initialState: State
    ): StateCreator<
        AdminState,
        [['zustand/devtools', never]],
        [],
        AdminState
    > =>
    (set) => ({
        ...initialState,
        setUser: (id: number, user?: User) =>
            set((state) => ({
                userStates: state.userStates.map(
                    (userState, i) =>
                        i === id
                            ? { ...userState, user }
                            : userState
                ),
            })),
        save: () => {
            set((state) => {
                sessionStorage.setItem('userStates', JSON.stringify(state))
                return state
            })
        },
        load: () => {
            const savedState = sessionStorage.getItem('userStates')
            if (savedState) {
                set(JSON.parse(savedState))
            }
        },
        reset: () => {
            set(initialState)
            sessionStorage.removeItem('userStates')
        },
    })

export const AdminModel = {
    createAdminSlice,
}
